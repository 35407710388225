<template>
  <div class="account-setting">
    <div class="account-setting__header">
      <p class="settings-heading">Account Settings</p>
    </div>
    <div class="account-setting__body">
        <div 
          data-cy="settingsPersonalInformation"
          class="settings"
          :class="{ active: isActive('personal') }"
          @click="select('personal')"
        >
          <p class="settings__link">Personal Information</p>
        </div>
        
      <div
        class="settings"
        :class="{ active: isActive('privacy') }"
        @click="select('privacy')"
      >
        <p class="settings__link">Privacy Protection</p>
      </div>
      <div
        class="settings"
        :class="{ active: isActive('payment') }"
        @click="select('payment')"
      >
        <p class="settings__link">Payment Methods</p>
      </div>
      <!-- <div
        class="settings"
        :class="{ active: isActive('notifications') }"
        @click="select('notifications')"
      >
        <p class="settings__link">Notifications and Controls</p>
      </div> -->
      <div
      data-cy="settingsSubscription"
        class="settings"
        :class="{ active: isActive('subscription') }"
        @click="select('subscription')"
      >
        <p class="settings__link">Subscription</p>
      </div>
      <div data-cy="settingsDeactiveMyAccount" class="settings" @click="showModal(true)">
        <p class="settings__link">
          Deactivate My Account
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLink: "personal",
    };
  },
  methods: {
    select(selected) {
      this.selectedLink = selected;
      this.$emit("update-component", selected);
    },
    isActive(link) {
      return this.selectedLink == link;
    },
    showModal(value){
      this.$emit('show-deactivate-modal', value)
    }
  },
};
</script>

<style lang="scss" scoped>
@include reset-css;

.account-setting {
  margin-right: 2rem;
  margin-top: 1.5rem;
  @include respond(phone-x-small) {
    margin-right: 0;
  }
  &__header {
    background-color: $color-primary;
    height: 6rem;
    display: flex;
    align-items: center;
    padding: 0 2rem;
    .settings-heading {
      color: $color-white;
      font-size: 1.8rem;
      font-family: $font-secondary-bold;
      @include respond(phone-large) {
        font-size: 1.6rem;
      }
    }
  }
  &__body {
    .settings {
      background-color: $color-white;
      height: 6rem;
      display: flex;
      align-items: center;
      padding: 0 2rem;
      cursor: pointer;
      &__link {
        color: $color-black;
        font-size: 1.6rem;
        font-family: $font-secondary;
        @include respond(phone-large) {
          font-size: 1.3rem;
        }
      }
    }
    // & > .settings:last-child {
    //   .settings__link {
    //     color: #be0000;
    //   }
    // }
    .active {
      background-color: #011e290a;
      border-right: 0.8rem solid $color-dark-blue-2;
      .settings__link {
        font-family: $font-secondary-bold;
      }
    }
  }
}
</style>