<template>
  <div class="notifications">
    <div class="notifications__header">
      <h4>Notifications and Controls</h4>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt
      </p>
    </div>
    <div class="notifications__body">
      <h4 class="notification-heading">Notifications settings</h4>
      <div class="notifications__text">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt
      </div>
      <div class="notifications__options">
        <div class="notification__option">
          <p>Connection Requests</p>
          <a-switch :checked="connectionReqChecked" @change="connectionReqChecked=!connectionReqChecked" />
        </div>
        <div class="notification__option">
          <p>Collaboration Requests</p>
          <a-switch :checked="collaborationReqChecked" @change="collaborationReqChecked=!collaborationReqChecked" />
        </div>
        <div class="notification__option">
          <p>New publishing</p>
          <a-switch :checked="newPublishChecked" @change="newPublishChecked=!newPublishChecked" />
        </div>
        <div class="notification__option">
          <p>News Headlines</p>
          <a-switch :checked="newsHeadlineChecked" @change="newsHeadlineChecked=!newsHeadlineChecked" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      connectionReqChecked: false,
      collaborationReqChecked: false,
      newPublishChecked: false,
      newsHeadlineChecked: false
    };
  }
};
</script>
<style lang="scss" scoped>
@include reset-css;
.notifications {
  background-color: $color-white;
  margin-top: 1.5rem;
  &__header {
    border-bottom: 0.24rem solid rgba(119, 119, 119, 0.123);
    padding: 1.5rem 4rem;
    h4 {
      font-size: 1.8rem;
      font-family: $font-secondary-bold;
    }
    p {
      font-size: 1.6rem;
      font-family: $font-secondary;
      padding: 0.5rem 0;
    }
  }
  &__body {
    padding: 2rem 4rem;
    font-size: 1.2rem;
    opacity: 0.8;
    font-family: $font-secondary;
    .notification-heading {
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
      opacity: 1;
    }
    .notifications__text {
      margin-bottom: 2rem;
    }
    .notifications__options {
      width: 30rem;
      @include respond(laptop-small) {
        width: 100%;
      }
      p {
      }
      .notification__option {
        display: flex;
        justify-content: space-between;
        margin: 1rem 0;
        .ant-switch {
          width: 18px;
          min-width: 35px;
          &::after {
            width: 14px;
            height: 14px;
            top: 3px;
          }
        }
        .ant-switch-checked {
          background-color: #70b74e;
        }
      }
    }
  }
}
</style>