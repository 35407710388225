<template>
  <div class="payment">
    <div class="payment__header">
      <h3 class="payment__heading">Payment Methods</h3>
      <p class="payment__text">
      </p>
      <div class="payment-navbar">
        <div
          class="nav"
          :class="{ active: isActive('payment-card') }"
          @click="select('payment-card')"
        >
          Payment Cards
        </div>
        <!-- <div
          class="nav"
          :class="{ active: isActive('withdrawal') }"
          
        >
          Withdrawal Banks
        </div> -->
      </div>
    </div>
    <div class="payment__body">
      <div v-if="selectedTab == 'payment-card'" class="cards-content">
        <h4 class="add-card-heading">Add New Card</h4>
        <form action="">
          <div class="pament-card">
            <label class="input-label" for="card-no">Card Number</label>
            <div class="all-pament-card">
              <div class="pament-card-img">
                <img
                  src="../../../assets/images/master-card.webp"
                  alt="Master Card"
                />
              </div>
              <div class="pament-card-img">
                <img
                  src="../../../assets/images/visa-card.webp"
                  alt="Master Card"
                />
              </div>
              <div class="pament-card-img">
                <img src="../../../assets/images/discover-logo.webp" alt="Master Card" />
              </div>
              <div class="pament-card-img">
                <img src="../../../assets/images/american-card.webp" alt="Master Card" />
              </div>
            </div>
          </div>

          <input
            v-model="card.number"
            id="card-no"
            type="text"
            placeholder="Card number"
            maxlength="19"
            @input="addSpace($event)"
          />
          <label class="input-label" for="name">Name on Card</label>
          <input v-model="card.name" id="name" type="text" placeholder="Enter Name" />
          <div class="card-details">
            <div class="card-expiry">
              <label class="input-label expiry-label" for="card-expiry"
                >Card Expiry</label
              >
              <input
              v-model="card.expiry"
                id="card-expiry"
                type="text"
                placeholder="MM/YY"
                maxlength="5"
                @input="validateCardExpiry($event)"
              />
            </div>
            <div class="card-cvv">
              <label class="input-label cvv-label" for="cvv">CVV</label>
              <input v-model="card.cvv" id="cvv" type="text" placeholder="CVV" maxlength="3" />
            </div>
          </div>
          <a-button :disabled="disableButton" @click="saveCardInfo()" class="update-profile-btn btn-primary" :loading="buttonLoader">Save Card Info</a-button>
        </form>
        <h4 class="added-cards-heading" v-if="addedCards.length>0">Added Cards</h4>
        <div class="added-cards">
          <div v-for="card in addedCards" :key="card.id" class="added-card">
            <div class="added-card__info">
              <div class="added-card__img">
                <img
                  :src="require(`../../../assets/images/${card.img}`)"
                  alt=""
                  class="image"
                />
              </div>
              <div class="added-card__details">
                <h5 class="added-card__name">
                  {{ card.name }}
                </h5>
                <p class="added-card__date">
                  {{ card.date }}
                </p>
              </div>
            </div>
            <a-dropdown :trigger="['click']" placement="topRight">
              <a class="ant-dropdown-link">
                <i class="icon icon-vertical_three-dots option-icon"></i>
              </a>
              <template #overlay>
                <a-menu>
                  <!-- <a-menu-item key="0">
                    <a>Inactive bank</a>
                  </a-menu-item> -->
                  <a-menu-item key="2">
                    <a @click="removeCard(card.id)" class="remove-btn">Delete</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>

      <div v-if="selectedTab == 'withdrawal'" class="bank-content">
        <h4 class="add-bank">
          Add New Bank
          <span class="note">(Listed banks are the ones we support)</span>
        </h4>
        <div class="banks">
          <div v-for="bank in banks" :key="bank.id" class="bank">
            <div class="bank__logo">
              <img
                :src="require(`../../../assets/images/${bank.img}`)"
                alt=""
                class="image"
              />
            </div>
            <div class="bank__name">
              {{ bank.name }}
            </div>
          </div>
        </div>
        <h4 class="added-cards-heading">Added Cards</h4>
        <div class="added-cards">
          <div class="added-card">
            <div class="added-card__info">
              <div class="added-card__img">
                <img
                  :src="require(`../../../assets/images/${banks[1].img}`)"
                  alt=""
                  class="image"
                />
              </div>
              <div class="added-card__details">
                <h5 class="added-card__name">
                  {{ banks[1].name }}
                </h5>
                <p class="added-card__no">02198917</p>
              </div>
            </div>
            <a-dropdown :trigger="['click']" placement="bottomRight">
              <a class="ant-dropdown-link">
                <i class="icon icon-vertical_three-dots option-icon"></i>
              </a>
              <template #overlay>
                <a-menu>
                  <a-menu-item key="0">
                    <a>Inactive bank</a>
                  </a-menu-item>
                  <a-menu-item key="2">
                    <a class="remove-btn">Disconnect bank</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </div>
        </div>
      </div>
    </div>
    <MessageToaster :visible="isToast" :message="toastMessage"  @handle-close="isToast = false" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import MessageToaster from "@/components/BaseComponents/MessageToaster"
export default {
  components: {
    MessageToaster,
  },
  data() {
    return {
      selectedTab: "payment-card",
      addedCards: [
        // {
        //   id: 1,
        //   name: "Muhammad - 8263",
        //   date: "6/23",
        //   img: "Mastercard-logo@2x.webp",
        // },
        // {
        //   id: 2,
        //   name: "Muhammad - 8263",
        //   date: "6/23",
        //   img: "Visa-Logo@2x.webp",
        // },
      ],
      banks: [
        {
          id: 1,
          name: "Bank of America",
          img: "Mastercard-logo@2x.webp",
        },
        {
          id: 2,
          name: "Chase Bank",
          img: "Visa-Logo@2x.webp",
        },
      ],
      card: {},
      toastMessage: {},
      isToast: false,
      buttonLoader: false,
      disableButton:false
    
    };
  },
  async mounted() { 
    this.fetchCards()
  },
  methods: {
    ...mapActions('payment',['postPaymentCardDetails','getPaymentCards','removePaymentCard']),
    select(tab) {
      this.selectedTab = tab;
    },
    isActive(tab) {
      return this.selectedTab == tab;
    },
    async saveCardInfo() { 
      if (this.card.name && this.card.cvv && this.card.expiry && this.card.number) {
        
        // create the payload
        const payload = {
          number: this.card.number,
          exp_month: parseInt(this.card.expiry.split('/')[0]),
          exp_year: parseInt(`20${this.card.expiry.split('/')[1]}`),
          cvc: this.card.cvv
        }
        this.buttonLoader = true
        //post to the api
        this.postPaymentCardDetails(payload).then(res => {
          if (res.status === 200) {

            this.card = {}
            // fetch the all the cards again 
            this.fetchCards()
            this.showToast('success', 'Card details saved successfully')
            this.buttonLoader = false
          }
        }).catch(err => {
          this.buttonLoader = false
          this.showToast('failure', 'Invalid card details')
        })
      }
      else {
        this.showToast('failure', 'Please fill out all the fields')

      }

      
    },
    async removeCard(cardId) { 
      this.removePaymentCard(cardId).then(res => { 
        if (res.status === 200) { 
          // fetch all the cards again
          this.fetchCards()
          this.showToast('success','Card removed successfully')
        }
      })
    },
    async fetchCards() { 
      
      // get all the cards
      const cardDetails = await this.getPaymentCards()
      //structure the cards accordingly and push to addedCards array
      this.addedCards = cardDetails.map(detail => {
        const card = {}
        card.id = detail.id
        card.name = `${detail.name} - ${detail.last4}`
        card.date = `${detail.exp_month}/${String(detail.exp_year).split('0')[1]}`
        switch (detail.brand) {
          case 'Visa':
            card.img = 'Visa-Logo@2x.webp';
            break;
          default:
            card.img = ''

        }
        return card

      })
    },
    showToast(type, title) { 
      this.toastMessage.type = type
      this.toastMessage.title = title
      this.isToast = true
    },
    addSpace(event) { 
      const number = event.target.value.replace(/ /g, '');
      this.card.number = number ? number.match(/.{1,4}/g).join(' ') : ''
    },
    validateCardExpiry(event) { 
      const expiry = event.target.value.replace(/\//g, '');
      if (expiry.match(/^[0-9]{2}$/g)) { 
        this.card.expiry = expiry + '/'
      }
     
    }
      
    }
    
  }

</script>
<style lang="scss" scoped>
@include reset-css;
.payment {
  background-color: $color-white;
  margin-top: 1.5rem;
  &__header {
    border-bottom: 0.24rem solid rgba(119, 119, 119, 0.123);
    padding: 1.5rem 4rem;
    .payment-navbar {
      display: flex;
      .nav {
        font-family: $font-secondary;
        font-size: 1.6rem;
        opacity: 0.5;
        cursor: pointer;
        &:not(:last-child) {
          margin-right: 4rem;
        }
      }
      .active {
        opacity: 0.8;
        font-family: $font-secondary-bold;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          width: 3rem;
          height: 0.5rem;
          background-color: $color-primary;
          top: 3.5rem;
          left: 0;
        }
      }
    }
  }
  &__heading {
    font-size: 1.8rem;
    font-family: $font-secondary-bold;
  }
  &__text {
    font-size: 1.6rem;
    font-family: $font-secondary;
    padding: 0.5rem 0;
    margin-bottom: 1rem;
  }
  &__body {
    padding: 2rem 4rem;
    .add-card-heading,
    .added-cards-heading {
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
      margin: 1rem 0;
    }
    form {
      margin-top: 2rem;
      .input-label {
        font-size: 1.6rem;
        font-family: $font-secondary;
        margin: 1.5rem 0;
        display: flex;
        justify-content: space-between;
        .code-set-text {
          font-size: 1.2rem;
        }
      }

      input {
        @include respond(laptop-small) {
          width: 100%;
        }
        width: 49rem;
        background-color: $color-light-grey-2;
        font-size: 1.6rem;
        font-family: $font-secondary;
        padding: 1rem 2rem;
        outline: none;
        border: 0.22rem solid #70707033;
      }

      .card-details {
        display: flex;
        width: 49rem;
        @include respond(laptop-small) {
          width: 100%;
        }
        .card-expiry {
          margin-right: 2rem;
          width: 100%;
        }
        .card-cvv {
          width: 100%;
        }
        input {
          width: 100%;
        }
      }
    }
    .added-cards {
      display: flex;
      width: 49rem;
      @include respond(laptop-small) {
        width: 100%;
      }

      .added-card {
        display: flex;
        width: 49rem;
        justify-content: space-between;
        margin-right: 1rem;
        background-color: #f6f8ff;
        padding: 1rem;
        align-items: center;
        @include respond(laptop-small) {
          width: 100%;
        }
        &__info {
          display: flex;
        }
        &__img {
          width: 4rem;
          height: 4rem;
          .image {
            width: 100%;
          }
        }
        &__details {
          margin-left: 0.7rem;
        }
        &__name {
          font-family: $font-primary-bold;
          font-size: 1.3rem;
        }
        &__date {
          font-family: $font-primary;
          font-size: 1rem;
        }
        .option-icon {
          color: black;
        }
      }
    }
    .bank-content {
      .add-bank {
        font-size: 1.6rem;
        font-family: $font-secondary-bold;
      }
      .note {
        opacity: 0.6;
        font-size: 1.2rem;
        font-family: $font-secondary;
      }
      .banks {
        display: flex;
        margin: 2rem 0;
        width: 49rem;
        @include respond(laptop-small) {
          width: 100%;
        }
        .bank {
          display: flex;
          width: 100%;
          margin-right: 1rem;
          background-color: #f6f8ff;
          padding: 1rem;
          align-items: center;
          &__name {
            font-family: $font-primary-bold;
            font-size: 1.3rem;
            margin-left: 1rem;
          }
        }
      }
      .added-cards {
        margin: 1.5rem 0;
        .added-card {
          &__no {
            font-size: 1.2rem;
            font-family: $font-primary;
            opacity: 0.6;
          }
        }
      }
    }
    .pament-card {
      display: flex;
      justify-content: space-between;
      width: 49rem;
      align-items: center;
      @include respond(laptop-small) {
        width: 100%;
      }
      .all-pament-card {
        display: flex;
        align-items: center;
        .pament-card-img {
          width: 4.5rem;
          height: fit-content;
          margin: 0 0.5rem;
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>