<template>
  <div class="privacy-protection-section">
    <div class="privacy-protection-section__header">
      <h3 class="privacy-protection-section__heading">Privacy Protection</h3>
      <!-- <p class="privacy__text">
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt
      </p> -->
    </div>
    <div class="privacy-protection-section__body">
      <h4 class="change-password-heading">Change your password</h4>
      <form>
        <label class="input-label" for="old-password">Old Password</label>
        <!-- <input
          id="old-password"
          type="password"
          v-model="oldPassword"
          placeholder="Enter Old Password"
        /> -->
        <a-input-password 
          v-model:value="oldPassword" 
          placeholder="Enter Old Password" 
          class="form-input" 
        />
        <label class="input-label" for="new-password">New Password</label>
        <a-input-password 
          v-model:value="newPassword" 
          placeholder="Create New Password" 
          class="form-input" 
        />
        <p class="error-message" v-if="newPassError">{{ newPassError }}</p> 
        <!-- <button class="request-code-btn btn-primary">Request Verification Button</button> -->
        <a-button :loading="loader" class="request-code-btn btn-primary" @click="changePassword()">Update</a-button>
        <!-- <label class="input-label" for="code">
          <p>Verification code</p>
          <p class="code-set-text">
            We've sent a verification code to your email
          </p>
        </label> -->
        <!-- <input id="code" type="text" placeholder="Enter Code" /> -->
        <!-- <br /> -->
        <!-- <button class="update-profile-btn btn-primary">Update Profile</button> -->
      </form>
    </div>
  </div>
  <message-toaster 
      :visible="showMessageModal"
      :message="message"
      @handle-close="showMessageModal = false"
      >
  </message-toaster>
</template>

<script>
import Cookies from "js-cookie";
import MessageToaster from  "../../BaseComponents/MessageToaster.vue";
export default {
  components: {
    MessageToaster
  },
  data(){
    return{
      oldPassword: "",
      newPassword: "",
      loader: false,
      newPassError: "",
      message: {},
      showMessageModal: false,
    }
  },
  methods: {
    async changePassword() {
      if (this.oldPassword === "" || this.newPassword === "") {
        this.newPassError = "Please provide both the old and new passwords.";
        return;
      }
      try {
        this.loader = true;
        const payload = {
          old_password: this.oldPassword,
          new_password: this.newPassword,
        };
        const response = await this.$store.dispatch("profile/updateCurrentPassword", payload);
        if (response.status === 200) {
          this.oldPassword = "";
          this.newPassword = "";
          this.showMessageModal = true;
          this.message = {
            title: "Your password has been changed.",
            type: "success",
          };
        }else if(response.status === 200 && response.data.message === "new password should contain more that 8 characters"){
            console.log(response.data.message)
            this.newPassError = response.data.message;
        }
      } catch (err) {
        if (err.response.data.new_password) {
          this.newPassError = err.response.data.new_password[0];
        }
        this.newPassError = err.response.data.message;
        console.log(err.response.data.message);
      } finally {
        this.loader = false;
      }
    },
  },
}
</script>
<style lang="scss">
@include reset-css;
.privacy-protection-section {
  background-color: $color-white;
  margin-top: 1.5rem;
  &__header {
    border-bottom: 0.24rem solid rgba(119, 119, 119, 0.123);
    padding: 1.5rem 4rem;
  }
  &__heading {
    font-size: 1.8rem;
    font-family: $font-secondary-bold;
  }
  &__text {
    font-size: 1.6rem;
    font-family: $font-secondary;
    padding: 0.5rem 0;
  }
  &__body {
    padding: 2rem 4rem;
    .change-password-heading {
      font-size: 1.6rem;
      font-family: $font-secondary-bold;
    }
    form {
      margin-top: 2rem;
      .input-label {
        font-size: 1.6rem;
        font-family: $font-secondary;
        margin: 1.5rem 0;
        display: flex;
        justify-content: space-between;
        width: 49rem;
        align-items: center;
        @include respond(laptop-small) {
          width: 100%;
        }
        .code-set-text {
          font-size: 1.2rem;
        }
      }
      input {
        @include respond(laptop-small) {
          width: 100%;
        }
        width: 49rem;
        background-color: $color-light-grey-2;
        font-size: 1.6rem;
        font-family: $font-secondary;
        padding: 1rem 2rem;
        outline: none;
        border: 0.22rem solid #70707033;
      }
      .error-message {
        font-size: 1.4rem;
        font-family: $font-primary;
        margin-top: 0.5rem;
        color: red;
      }
      .checkbox{
        display: flex;
        flex-direction: row;
        width: fit-content;
        margin-top: 1.5rem;
        .ant-checkbox-wrapper {
          margin-left: 0;
          font-size: 1.6rem;
          font-family: $font-secondary;
          width: fit-content;
          margin-bottom: 1rem;
          line-height: 2rem;

          .ant-checkbox {
            .ant-checkbox-inner {
              border: 0.18rem solid rgba(128, 128, 128, 0.377);
              height: 18px;
              width: 18px;
              border-radius: 0;
            }
            .ant-checkbox-input {
              width: fit-content;
            }
          }
        }
        .ant-checkbox-wrapper-checked {
          .ant-checkbox-checked {
            .ant-checkbox-inner {
              background-color: $color-primary;
              border-color: $color-primary;
              height: 18px;
              width: 18px;
              border-radius: 0;
            }
          }
        }
      }
      .request-code-btn {
        display: block;
        border: none;
      }
      .form-input {
        height: 4.5rem;
        border: 1px solid #24242433;
        margin-bottom: 1.4rem;
        padding: 1.2rem 2rem;
        font-size: 1.6rem;
        font-family: $font-primary;
        box-shadow: none;
        outline: none;
        color: $color-black;
        border-radius: 0;
        width: 49rem;
        background-color: $color-light-grey-2;
        @include respond(laptop-small) {
          width: 100%;
        }
        &::placeholder {
          opacity: 0.4;
        }
        .ant-input {
          font-size: 1.6rem;
          font-family: $font-primary;
          color: $color-black;
          background-color: $color-light-grey-2 !important;
          &::placeholder {
            opacity: 0.5;
            color: $color-black;
          }
        }
      }
    }
  }
}
</style>